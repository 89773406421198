<template>
  <div style="width: 100% ;padding: 10px">
    <el-row>
      <div style="margin: 10px 0">
        <el-input v-model="search" placeholder="请输入关键词" style="width:35%"></el-input>
        <el-button type="primary" style="margin-left:5px" @click = "select(search)">查询</el-button>
        <el-button type="primary" @click="select()">重置</el-button>
      </div>
    </el-row>
    <el-row>
      <el-col v-for="(item,index) in QRCodeList" style="padding-top: 5px;padding-left: 5px" :span="4">
        <el-card>
          <el-button type="text" @click="getPhotoUrl(item)">
            {{item}}
          </el-button>
        </el-card>
      </el-col>
    </el-row>
    <!--  弹窗  -->
    <el-dialog :visible.sync="dialogVisible" :title="name" width="20%">
      <img :src="(url)" alt="" style="width: 100%">
      <el-button @click="img_download()">下载</el-button>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  data() {
    return {
      search: '',
      QRCodeList: [],
      name:"",
      url:"",
      dialogVisible: false
    }
  },
  //加载表格
  mounted() {
    this.select()
  },
  methods: {
    getPhotoUrl(name){
      this.name = name
      this.dialogVisible = true
      this.url =
          "https://back.yilanbiaoge.cn/getQRcode/"+
          (name
              .replace("+","%2B")
              .replace("/","%2F")
              .replace("?","%3F")
              .replace("#","%23")
              .replace("&","%26")
              .replace("=","%3D"))
    },
    //整表查询
    select(search) {
      this.search = search;
      request.post("/getQRCodeList",
          search = (search===undefined || search.length===0)?'-':search).then(res => {
        this.QRCodeList = res.data
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取二维码列表失败'
        });
      }
    },
    GetMoban(){
      request.post("/GetMoban").then(res => {
        this.gongxumubanList = res.data
      })
    },
    img_download(){
      /*let src = this.url
      //src是图片路径
      var eleLink = document.createElement('a');
      eleLink.download = src;
      eleLink.style.display = 'none';
      //字符内容转变成blob地址
      eleLink.href = src;
      //触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      //然后移除
      document.body.removeChild(eleLink);*/
      this.getBlob().then(blob => {
        this.saveAs(blob, this.name+".png");
      });
    },
    getBlob() {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', this.url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };

        xhr.send();
      });
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        const body = document.querySelector('body');

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        // fix Firefox
        link.style.display = 'none';
        body.appendChild(link);

        link.click();
        body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
      }
    }
  }
}

</script>

<style scoped>

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 300px;
}
</style>